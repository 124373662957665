import { useRouter } from "next/router";
import { useEffect } from "react";

import { useGetLoggedIn } from "_common/hooks/auth";

import Presentation from "./presentation";

const Component = () => {
	const isLoggedIn = useGetLoggedIn ();
	const router     = useRouter ();

	useEffect ( () => {
		if ( !isLoggedIn )
			return;

		const origin = router.query.origin || "";

		router.replace ( `/${ origin }` );
	}, [ isLoggedIn ] );

	return (
		<Presentation />
	);
};

export default Component;
