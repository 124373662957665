import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {
	return {
		mainContainer: {
			backgroundColor : theme.other.colors.background.secondary,
			display         : "grid",
			placeItems      : "center",
			height          : "85vh"
		},
		container: {
			textAlign : "center",
			width     : "90%",
			maxWidth  : "40rem",
			margin    : "auto"
		},
		header: {
			marginBottom: "3rem"
		},
		logo: {
			height       : "5rem",
			marginBottom : "1.25rem"
		},
		headerTitle: {
			fontSize   : "2rem",
			fontWeight : "500"
		},
		footer: {
			display        : "flex",
			alignItems     : "center",
			justifyContent : "center",
			padding        : "0 3rem",
			marginTop      : "15rem",
			position       : "absolute",
			bottom         : "5%",
			width          : theme.other.isMobile ? "30vw" : "80vw"
		},
		footerText: {
			fontSize       : "1.5rem",
			color          : theme.other.colors.text.secondary,
			textDecoration : "none",
			marginRight    : "1rem",

			"&:not(:last-child):after": {
				content      : "''",
				color        : theme.other.colors.text.secondary,
				display      : "inline-block",
				background   : theme.other.colors.text.secondary,
				borderRadius : "100%",
				height       : "5px",
				width        : "5px",
				marginLeft   : "1rem",
				marginBottom : "0.1rem",
				alignSelf    : "center"
			}
		}
	};
} );
