import { initialValidations } from "_common/ssrWrapper";
import Login                  from "_components/pages/login";

const component = () => {
	return <Login />;
};

const getServerSideProps = async context => initialValidations ( context, async defaultProps => {

	const isLoggedIn = defaultProps?.isLoggedIn || false;

	if ( isLoggedIn )
		return {
			redirect: {
				destination : "/",
				permanent   : false
			}
		};

	return {
		props: {
			...defaultProps,
			noLayout   : true,
			noAuth     : true,
			isLoggedIn : defaultProps?.isLoggedIn || false
		}
	};
} );

export { getServerSideProps };

export default component;
