import dynamic from "next/dynamic";

import Icon from "_components/atoms/icon";

import useStyles from "./styles";

const LoginContainer = dynamic ( () => import ( "_components/pages/auth" ), { ssr: false } );

const component = () => {
	const { classes } = useStyles ( undefined, { name: "login-page" } );

	return (
		<div className = { classes.mainContainer }>
			<div className = { classes.container }>
				<header className = { classes.header }>
					<Icon
						className = { classes.logo }
						name      = "rooterLogo"
					/>
				</header>

				<LoginContainer
					isFrom          = { "Header" }
					page            = { "activate" }
					showLoginDialog = { true }
				/>

				<footer className = { classes.footer }>
					<a
						className = { classes.footerText }
						href      = "https://pages.rooter.gg/about-us"
						rel       = "noreferrer"
						target    = "_blank"
					>
						About Us
					</a>

					<a
						className = { `${ classes.footerText } middle` }
						href      = "https://pages.rooter.gg/terms-of-service"
						rel       = "noreferrer"
						target    = "_blank"
					>
						Terms & Conditions
					</a>

					<a
						className = { classes.footerText }
						href      = "https://pages.rooter.gg/privacy-policy"
						rel       = "noreferrer"
						target    = "_blank"
					>
						Privacy Policy

					</a>
				</footer>

			</div>
		</div>
	);
};

export default component;
